import React from 'react'
import './Service.css'
import img1 from '../../assets/images/images/services.png'
const Service = () => {
  return (
    <div className="services container">
        <div className="service-title">
            <img src={img1} alt="" />
            <h5>Our Services</h5> 
            <h1>Our services are designed to meet the needs of our customers</h1>
        </div>
        <div className="service-main">
        <div className="service1">
        <i class="bi bi-wallet-fill"></i>
            <h3>Commercial Management</h3>  
            <p>Blackhull Commercial management team constitutes embedded professionals & domain experts 
                with extensive domestic & international experience in ship chartering & brokering.</p>
                <button className='btnn'>Read More</button>
        </div> 
        <div className="service1">
        <i class="bi bi-people-fill"></i>
            <h3>Technical Management</h3>  
            <p>Blackhull follows a Planned Management System which is being particularly designed to
                 optimize Safety with operating efficiency. Monitoring through regular inspections
                  and data exchange.</p>
                  <button className='btnn'>Read More</button>
        </div>  
        </div>
        <div className="service-main">
        <div className="service1">
        <i class="bi bi-symmetry-vertical"></i>
            <h3>Crew Management</h3>  
            <p>Blackhull’s Crewing Department is headed by a team of well trained & experienced Master 
                Mariners & Chief Engineers who understand the fact that seafarers are the back bone 
                of the shipping industry.</p>
                <button className='btnn'>Read More</button>
        </div> 
        <div className="service1">
        <i class="bi bi-wechat"></i>
            <h3>Consultancy Services</h3>  
            <p>In today’s era of high customer expectations, businesses naturally aspire to the 
                highest levels of service, from order confirmation to guaranteed delivery dates.</p>
                <button className='btnn'>Read More</button>
        </div>  
        </div>
    </div>
  )
}

export default Service