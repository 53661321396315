import React from 'react'
import './Choose.css'
import img1 from '../../assets/images/images/target-copy.png'
const Choose = () => {
  return (
    <div className="why-us container">
        <div className="why-title">
          <img src={img1} alt="" />
            <h1>Why Choose Blackhull Maritime ?</h1>
            <p>Blackhull Maritime Services Private Limited Is Duly Authorized By The Govt. Of India,
                 Ministry Of Shipping for Crew Management Services With MLC 2006 & RPS License: Mum486. 
                  Company IMO No 6039910, Also an ISO 9001:2015 Certified by IRS Class.</p>
        </div>
            <div className="why-main">
            <h3><i class="bi bi-arrow-right"></i>Exclusive customer protection.</h3>
            <h3><i class="bi bi-arrow-right"></i>Tailor made & targeted Solutions.</h3>
            <h3><i class="bi bi-arrow-right"></i>One Technical Superintendent in charge of 2 Vessel - thus better supervision.</h3>
            <h3><i class="bi bi-arrow-right"></i>Certified Welders, Fitters, and Cleaners with maintenance equipment for upgrading Vessel.</h3>
            <h3><i class="bi bi-arrow-right"></i>Dedicated Electrical Superintendents.</h3>
          </div>
    </div>
  )
}

export default Choose