import React from 'react'
import "./Navbar.css"
import img from '../../assets/images/images/Design.png'

const Navbar = () => {
  return (
    <nav className='container'>
        <img src={img} alt="" className='logo'/>
        
        <ul>
            <li><a href="">HOME</a></li>
            <li><a href="">ABOUT US</a></li>
            <li><a href="">OUR SERVICES</a>
            <div className="dropdown-menu">
              <ul>
                <li className='active'><a href="">Crew Mnagement</a></li>
                <li><a href="">Technical Mnagement</a></li>
                <li><a href="">Commercial Mnagement</a></li>
                <li><a href="">Consultancy Services</a></li>
              </ul>
            </div>
            </li>
            <li><a href="">CAREERS AT BHMS</a>
            <div className="dropdown-menu-bhms">
            <ul>
                <li><a href="">Sea Jobs</a></li>
                <li><a href="">Shore Jobs</a></li>   
              </ul>
            </div>
            </li>
            <li><a href="">PRE SEA COURSES</a></li>
            <li><a href="">CONTACT US</a></li>
            <li><a href=""><button className='btn'>JOIN US</button></a></li>
        </ul>
        <div className="toggle">
        <i class="bi bi-list"></i>
        </div>
    </nav>
  )
}

export default Navbar