import React from 'react'
import './Header.css'

const Header = () => {
  return (
    <div className='Header container'>
     <div className="head-text">
       <h1>THINK OF SUCCESS</h1>
       <p>GET FREE CONSULTATION</p>
       <button className='btn'>GET AN APPOINTMENT</button>
     </div>

    </div>
  )
}

export default Header