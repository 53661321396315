import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <div className="footer container">
     <div className="footer-title">
    <h1> <i class="bi bi-envelope-fill"></i>Get your free consultation right now!</h1>
        <button className='btn'>MAKE AN APPOINTMENT</button>
     </div>
     <div className="footer-main">
      <div className="footer-text">
       <h5>CONTACT US</h5>
       <p><i class="bi bi-telephone-plus-fill"></i>+022-27560351</p>
       <p><i class="bi bi-envelope-at-fill"></i>info@yourdomain.com</p>
       <h5>VISIT US</h5>
       <p><i class="bi bi-geo-alt-fill"></i>805, NMS Titenium, CBD Belapur, Navi Mumbai.</p>
      </div>
      <div className="footer-text">
        <h5>QUICK LINKS</h5>
        <li><a href="">HOME</a></li>
        <li><a href="">ABOUT US</a></li>
        <li><a href="">OUR SERVICES</a></li>
        <li><a href="">CAREER AT BHMS</a></li>
        <li><a href="">PRE SEA COURSES</a></li>
        <li><a href="">CONTACT US</a></li>

      </div>
      <div className="footer-text">
         <h5>FOLLOW US</h5>
         <li><i class="bi bi-facebook"></i></li>
         <li><i class="bi bi-twitter"></i></li>
         <li><i class="bi bi-instagram"></i></li>
         <li><i class="bi bi-linkedin"></i></li>

      </div>
     </div>
     <div className="footer-bar">
     <p>©  2016-24 Blackhull Maritime Services Private Limited | All rights reserved.. </p>
     </div>
    </div>
  )
}

export default Footer