import { Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import Header from './Components/Header/Header';
import AboutUs from './Components/AboutUs/AboutUs';
import Service from './Components/Services/Service';
import Advisor from './Components/Advisor/Advisor';
import Choose from './Components/Choose/Choose';
import Update from './Components/Update/Update';
import Footer from './Components/Footer/Footer';

function App() {
  return (
    <>
     <Navbar/>
     <Header/>
     <AboutUs/>
     <Service/>
     <Advisor/>
     <Choose/>
     <Update/>
     <Footer/>
    </>
  );
}

export default App;
