import React from 'react'
import './Update.css'
import img1 from '../../assets/images/images/accounting-com-270x188.gif'
import img2 from '../../assets/images/images/Depositphotos_79638676_xl-2015-1-270x188.jpg'
import img3 from '../../assets/images/images/receptionist-270x188.gif'
import img4 from '../../assets/images/images/Business-Consulting-1-sataware-1-270x188.gif'
import img5 from '../../assets/images/images/yongding-270x188.jpg'
import img6 from '../../assets/images/images/WhatsApp-Image-2021-07-13-at-7.08.20-PM-270x188.jpeg'


const Update = () => {

  return (
    
    <div className="update container">  
    <div className="update-title">
        <h1>LATEST UPDATE</h1>
      </div>
        <div className="update-main">
          <div className="update-box">
            <img src={img1} alt="" />
            <h4>Finance Executive-Job</h4>
            <h6>Oct-26-2023 Admin 62345 views</h6>
            <h5>Location- Gujarat</h5>
            <p>Need a Finance Executive manager-over account department,knowledge on Data entry(Excel)
              and excellent communication skills.</p>
          </div>
          <div className="update-box">
            <img src={img2} alt="" />
            <h4>Crew and Ship Manager </h4>
            <h6>Aug-18-2022 Admin 56784 views</h6>
            <h5>Location- Mumbai</h5>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic quaerat iste aliquam 
              eligendi nisi!</p>
          </div>
          <div className="update-box">
            <img src={img3} alt="" />
            <h4>HR Manager- (Female)</h4>
            <h6>March-05-2023 Admin 35763 views</h6>
            <h5>Location- Chennai</h5>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic quaerat iste aliquam 
              eligendi nisi!</p>
          </div>
          <div className="update-box">
            <img src={img4} alt="" />
            <h4>Social Media Manager-(Female) </h4>
            <h6>Dec-16-2022 Admin 45673 views</h6>
            <h5>Location- Gujarat</h5>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic quaerat iste aliquam 
              eligendi nisi!</p>
          </div>
          <div className="update-box">
            <img src={img5} alt="" />
            <h4>Ships Tanker Maintance-LPG</h4>
            <h6>Feb-21-2024 Admin  81254 views</h6>
            <h5>Location- Chennai</h5>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic quaerat iste aliquam 
              eligendi nisi! </p>
          </div>
          <div className="update-box">
            <img src={img6} alt="" />
            <h4>VLCC/SUEZMAX/AFRAMAX- Handler</h4>
            <h6>May-30-2022 Admin 24651 views</h6>
            <h5>Location- Pune</h5>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic quaerat iste aliquam 
              eligendi nisi! </p>
            <p></p>
          </div>
        </div>
    </div>
  
  )
}

export default Update