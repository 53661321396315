import React from 'react'
import './About.css'
import img1 from '../../assets/images/images/wheels.png'
import img2 from '../../assets/images/images/direction-news.png'

const AboutUs = () => {
  return (
   <div className="aboutus container">
    <div className="about-head">
      <img src={img1} alt="" />
       <h5>ABOUT US</h5>
       <h1>We Make Perfect Solution in Shipping Industry</h1>
    </div>
        <div className="about-main">
            <div className="about1">
              <h1>10 + </h1>
              <h4>Years of Experiences in Maritime Industry</h4>
             
              <p>Blackhull Maritime Services Private Limited is a young, emerging business partner in the
                 maritime industry in providing multi-nationality maritime personnel to various shipping, 
                 offshore, onshore, oil & gas industries. Our management team is located in the hub of
                  maritime industry in India at Navi Mumbai with branch offices in Jaipur, 
                  Istanbul (Turkey) & Dubai (UAE).</p>
              <p>Established by Mahendra Singh Muwal, in effecting our managerial obligations, we place 
                atmost importance to safety & commercial efficiency. The fact that our pool has a large 
                supply of qualified & competent multi-national personnel makes our clients realize the long-term 
                benefits of the well trained and organized work force that we supply.</p>
                <button className='btn'>Read More</button>
            </div>
            <div className="about2">
                <div className="about-text">
                <h3><i class="bi bi-boxes"></i>A smooth and fast communication</h3>
                <p>In today’s world of communications and the speed with which it develops is critical to 
                  closing a business or not.</p>
                </div>
                <div className="about-text">
                <h3><i class="bi bi-x-diamond-fill"></i>Trust and experience</h3>
                <p>The important thing is to never miss a customer or agent. 
                  The essential thing is to keep them.</p>
                </div>
                <div className="about-text">
                <h3><i class="bi bi-graph-up-arrow"></i>Achieve Your Goals</h3>
                <p>When you set goals for yourself, it is important that they motivate you: this means 
                  making sure that they are important to you, and that there is value in achieving them.</p>
                </div>
            </div>
        </div>
        
          <div className="grow-main">
             <div className="grow1">
              <h1>Video</h1>
             </div>
             <div className="grow2">
              <div className="grow-text">
                <h3><i class="bi bi-person-fill-add"></i>Growth Initiative</h3>
                <p>With discipline & focus on the core business for making operational excellence we 
                  initiate small & maverick ideas by using selective acquisition & alliance to
                   catalyze growth.</p>
              </div>
              <div className="grow-text">
                <h3><i class="bi bi-graph-up-arrow"></i>Community</h3>
                <p>As an emerging business partner we ensure that an unbiased leadership is maintained 
                  in all areas of our industry communities.</p>
              </div>
              <div className="grow-text">
                <h3><i class="bi bi-trophy-fill"></i>Outlook</h3>
                <p>An open way of thinking & understanding related to industry matters we thrive ahead 
                  with an ever bird eye view on to all prospects of shipping industry.</p>
              </div>
             </div>
          </div>

          <div className="choose-main">
              <div className="choose1">
                <h1>Why Choose Us</h1>
                <p>Blackhull Maritime Services Private Limited Is Duly Authorized By The Govt. Of India,
                   Ministry Of Shipping for Crew Management Services With MLC 2006 & RPS License: Mum – 486,
                    With Company IMO No 6039910, Also an ISO 9001:2015 | ISO 14001:2015 | OHSAS 18001:2007 |
                     HACCP Certified Company.</p>
                <div className="choose-text">
                   <h3><i class="bi bi-rocket-takeoff-fill"></i>Transformation</h3>
                   <p>Modify & Alter our management system on a day to day basis with the progress in 
                    industry standards for effectiveness of global clients.</p>
                </div>
                <div className="choose-text">
                   <h3><i class="bi bi-box-fill"></i>Sustainability</h3>
                   <p>To avoid the depletion of our resources to maintain a balance in operation of our 
                    management services we pursue to maintain a global level of economic growth.</p>
                </div>
                <div className="choose-text">
                   <h3><i class="bi bi-headset"></i>People & Safety</h3>
                   <p>Priority is given on a special note for selection of personnel & Special steps are 
                    insured for safety of these personnel working onboard our global clients team.</p>
                </div>
              </div>
              <div className="choose2">
                <img src={img2} alt="" />
                    <h1>Your future is Calling</h1>
                    <p>Creating value in a sustainable way, using innovative technologies, fresh ideas 
                      and new partners, we are consistently thinking bigger. Which is where you come in.
                       We are always looking for experienced, engaged and inspiring colleagues 
                       to help get us to where we need to be.</p>
                       <form >
                        <label>Name</label>
                        <input type="text" name="name" placeholder='Name' required/>
                        <label>Phone</label>
                        <input type="tel" name="phone" placeholder='Mobile No.' required/>
                        <label>Email</label>
                        <input type="email" name="email" placeholder='Email' required/>
                        <label>Write your message here</label>
                        <textarea name="message" cols='30' rows='6' placeholder='Message' required/>
                       </form>
                       <button className='btn'>MAKE AN APPOINTMENT</button>
              </div>
          </div>
   </div>

  
  )
}

export default AboutUs