import React from 'react'
import './Advisor.css'
import { Card } from 'react-bootstrap'
import img1 from '../../assets/images/images/ceo-and-founder.jpg'
import img2 from '../../assets/images/images/david-tevfik-sayin-1523521568.jpg'
import img3 from '../../assets/images/images/spkrwmf32.jpg'
import img4 from '../../assets/images/images/support-hand.png'

const Advisor = () => {
  return (
    <div className="advisor container">
        <div className="advisor-title">
          <img src={img4} alt="" />
           <h5>MEET THE EXPERTS</h5>
           <h1>Our Advisory Team</h1>
       </div>
        <div className="advisor-main">
            <div className="advisor-img">
                <img src={img1} alt="" />
                <h2>Mahendra Muwal</h2>
                <p>MD & Founder</p>
               <h6>management@blackhullmaritime.com</h6>
                <i class="bi bi-facebook"></i>
                <i class="bi bi-twitter"></i>
                <i class="bi bi-linkedin"></i>
              </div>
              <div className="advisor-img">
                <img src={img2} alt="" />
                <h2>Capt. Tevfik David Sayin</h2>
                <p>Head- Istanbul office</p>
                <h6>istanbul@blackhullmaritime.com</h6>
                <i class="bi bi-facebook"></i>
                <i class="bi bi-twitter"></i>
                <i class="bi bi-linkedin"></i>
            </div>
            <div className="advisor-img">
              <img src={img3} alt="" />
              <h2>Capt. Joel Bandugula</h2>
              <p>CBDO</p>
              <h6>dgm@blackhullmaritime.com</h6>
                 <i class="bi bi-facebook"></i>
                <i class="bi bi-twitter"></i>
                <i class="bi bi-linkedin"></i>
           </div>
        </div>
    </div>
   
  )
}

export default Advisor